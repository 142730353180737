import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import Pattern from "../components/Layout/Pattern"
import teenBootcampImage from "../images/1x/teen-chef.png"
import { Helmet } from "react-helmet"
import DarkMode from "../components/Layout/DarkMode"
import useScroll from "../hooks/useScroll"

const IndexPage = () => {
  const scrollTo = useScroll()

  return (
    <DarkMode title="Black Chef African Scholarship">
      <Helmet>
        <meta
          name="og:url"
          content="https://www.cmkculinary.org/black-chef-africa-foundation"
        />
        <meta name="og:image" content={teenBootcampImage} />
        <link
          rel="canonical"
          href="https://www.cmkculinary.org/black-chef-africa-foundation"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>

      <SEO
        title="Black Chef Africa Foundation | CMK Culinary"
        description="The BCA FOUNDATION is a CMK Culinary initiative scholarship program designed to help young and passionate aspiring chefs across Africa to achieve their dreams of becoming professional chefs."
      />

      <section className="container mx-auto px-4 overflow-hidden">
        <section
          id="first-section"
          className="flex flex-col-reverse md:flex-row items-center w-full pb-24  md:pb-32"
        >
          <div className="flex-1 space-y-8">
            <h1 className="text-4xl lg:text-6xl font-bold">
              <span className="font-display text-white leading-2 tracking-tight">
                Black Chef Africa
              </span>
              <br />
              <Cursive className="-top-4 relative text-yellow600">
                Scholarship
              </Cursive>
            </h1>

            <div className="w-full max-w-xl select-none">
              <p className="leading-5 md:leading-auto text-white font-body max-w-lg mb-4 md:mb-8">
                The BCA FOUNDATION is a CMK Culinary initiative scholarship
                program designed to help young and passionate aspiring chefs
                across Africa 🌍, to achieve their dreams of becoming
                professional chefs.
              </p>
            </div>

            <section className="mt-12 select-none pt-12">
              <div className="flex flex-col">
                <button
                  className="text-yellow600 font-bold border border-yellow600 inline-block p-4 w-full md:w-64 text-center"
                  onClick={() => {
                    console.log(document.querySelector("#how-to-participate"))
                    scrollTo(
                      0,
                      0,
                      document.querySelector("#how-to-participate")
                    )
                  }}
                >
                  I&apos;m interested
                </button>
                <marquee className="text-yellow600 font-bold inline-block p-4 w-full md:w-64 ">
                  ENTRY OPENS MAY 2022 *
                </marquee>
              </div>
            </section>
          </div>

          <div className="flex-1 w-full md:w-auto my-10 md:my-24 mb-24 transform scale-110 items-center">
            <div
              className="w-32 h-32 absolute right-0 top-0 transform md:translate-x-1/3 z-0 -translate-y-1/2 rounded-full"
              style={{
                backgroundImage: `url(${
                  require("../images/pattern-3.svg").default
                })`,
              }}
            />
            <div
              className="w-32 h-32 absolute bottom-0 transform z-20 -translate-x-1/2 translate-y-1/2"
              style={{
                backgroundImage: `url(${
                  require("../images/pattern-1.svg").default
                })`,
              }}
            />
            <StaticImage
              src="../images/hero-image.png"
              alt="CMK Alumni"
              layout="fullWidth"
              className="z-10 relative"
            />
          </div>
        </section>
      </section>

      <section className="flex flex-col md:flex-row">
        <div className="flex-1 flex items-center justify-center bg-black100 text-white py-32 box-border ">
          <div className="max-w-xs space-y-8 text-center">
            <Heading>
              <Cursive className="text-yellow600 font-cursive">Our</Cursive>
              &nbsp;Aim
            </Heading>
            <p className="opacity-75">
              To provide that platform for young and passionate African chefs to
              rise through culinary initiatives.
            </p>
          </div>
        </div>

        <div className="flex-1 flex items-center justify-center bg-gray-700 text-white py-24 box-border">
          <div className="max-w-xs space-y-8 text-center">
            <Heading>
              <Cursive className="text-yellow600 font-cursive">Our</Cursive>
              &nbsp;Mission
            </Heading>
            <p className="opacity-75 px-2">
              To develop and breed the next generation of chefs
            </p>
          </div>
        </div>
      </section>

      <section className="flex flex-col md:flex-row items-stretch">
        <figure className="w-full md:w-5/12 relative bg-black">
          <img
            src={require("../images/black-chef-process-image.png").default}
            alt="The process"
            className="object-cover w-full h-full"
          />
        </figure>

        <section className="flex md:flex-1 items-center justify-center text-white p-8 bg-black z-20 py-24">
          <div className="space-y-8">
            <Heading>
              <Cursive className="text-yellow600">The</Cursive> Process
            </Heading>
            <p className="max-w-md opacity-75">
              Through the BCA Foundation, all culinary scholarship program will
              be competitive, giving every selected participant the opportunity
              to prove their inner strength. The board of CMK Culinary through
              this initiative has agreed on a perfectly designed system that
              will enable us pick the best candidates across Africa, starting
              with Nigeria in this first edition.
            </p>
          </div>
        </section>
      </section>

      <div
        id="how-to-participate"
        className="py-24 text-white relative z-10 overflow-hidden"
      >
        <StaticImage
          src="../images/logo-bca.png"
          className="absolute w-2/5 top-0 right-0 scale-150 transform -rotate-45 translate-x-1/2 opacity-25 z-0"
        />

        <div className="container mx-auto px-6 space-y-24">
          <div className="flex flex-col items-start space-y-8 z-10 relative">
            <h2 className="text-4xl md:text-7xl">
              <Cursive className="opacity-76">How to</Cursive>
              &nbsp;&nbsp;
              <Cursive className="text-yellow600">Participate</Cursive>
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-5 gap-24 w-full z-10 relative">
            {[
              {
                content: (
                  <>
                    All interested participants will make a 1-minute video of
                    themselves telling or showing us why he/she deserves an
                    opportunity to contest for the scholarship. Video must not
                    exceed 1-minute and should be sent to us via email to
                    <b>info@cmkculinary.org</b> with the participant&apos;s
                    Facebook and Instagram handles
                  </>
                ),
              },
              {
                content: `All videos will be uploaded on the following handles @cmkculinary, @bcafoundation and the participant will be tagged.`,
              },
              {
                list: [
                  "Participants will encourage his/her followers, fan, family to Like and Follow @cmkculinary and @bcafoundation.",
                  "Write a convincing testimonial about the contestant why he/she should be selected.",
                  "Tag others to do so to increase your chance.",
                ],
              },
              {
                content: `Our team of judges will critically go through the videos at the end of voting period to select the best 10 contestant across Nigeria 🇳🇬 and Africa.`,
              },
              {
                content: `All 10 selected contestants will be invited over to compete for the Full / Half / Quarter scholarships offered by the BCA Foundation.`,
              },
            ].map((e, idx) => {
              return (
                <>
                  <div className="col-span-3">
                    <Stage key={idx} count={idx + 1}>
                      {e.content}
                      <ul className="space-y-2">
                        {(e.list || []).map((list_item, idx) => (
                          <li key={idx} className="flex">
                            <span className="w-10 flex-shrink-0 inline-block font-bold">
                              0{idx + 1}.
                            </span>
                            <p>{list_item}</p>
                          </li>
                        ))}
                      </ul>
                    </Stage>
                  </div>
                  {idx % 2 === 0 && (
                    <>
                      <div className="hidden md:block" />
                      <div className="hidden md:block" />
                      <div className="hidden md:block" />
                      <div className="hidden md:block" />
                    </>
                  )}
                </>
              )
            })}
          </div>
        </div>
      </div>

      <Pattern size={32} className="w-full h-24 bottom-0" />
    </DarkMode>
  )
}

const Heading = props => {
  // eslint-disable-next-line react/prop-types
  const children = props.children

  return (
    <h1 className="text-3xl md:text-4xl font-display tracking-tight font-bold">
      {children}
    </h1>
  )
}

const Stage = props => {
  // eslint-disable-next-line react/prop-types
  const { children, count } = props

  return (
    <div className="flex flex-col md:flex-row py-8 items-start w-full space-y-6 md:space-y-0">
      <div className="flex items-start lg:mr-6">
        <span
          className="text-yellow600 inline-block text-sm transform rotate-180 font-bold"
          style={{ writingMode: "vertical-rl", letterSpacing: "4px" }}
        >
          STAGE
        </span>
        <span
          className="text-7xl md:text-8xl font-bold font-display inline-block"
          style={{ lineHeight: "24px" }}
        >
          0{count}
        </span>
      </div>

      <p className="max-w-md text-sm md:text-base text-white text-opacity-60 w-full md:px-0">
        {children}
      </p>
    </div>
  )
}

// eslint-disable-next-line react/prop-types
const Cursive = ({ children, className }) => {
  return (
    <span
      className={["font-cursive tracking-normal font-normal", className].join(
        " "
      )}
    >
      {children}
    </span>
  )
}

export default IndexPage
