import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import { Footer } from "./Footer"
import Theme from "../../styles/Theme"
import { ThemeProvider } from "@wigxel/react-components"

const DarkMode = ({ title, children }) => {
  return (
    <ThemeProvider theme={Theme}>
      <main className="font-body bg-black500">
        <Header siteTitle={title || `CMK Culinary`} />
        <div>
          <main>{children}</main>
        </div>
        <Footer />
      </main>
    </ThemeProvider>
  )
}

DarkMode.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default DarkMode
